'use client'

import { useEffect } from 'react'

export default function Error({ error }) {
  useEffect(() => {
    window.location.href = '/error.html'
  }, [error])

  return null
}
